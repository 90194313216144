import React, { useEffect, useRef } from 'react';
import Modal from 'react-modal';
import Animation from './animations';

// modal after successfully activating a cumuloctiy device

interface ISuccessModalCumulocity {
  t: any;
  showSuccessModalCumulocity: boolean;
  setShowSuccessModalCumulocity: React.Dispatch<React.SetStateAction<boolean>>;
  domain: string;
  article: string | null;
}

const SuccessModal: React.FC<ISuccessModalCumulocity> = ({
  t,
  showSuccessModalCumulocity,
  setShowSuccessModalCumulocity,
  domain,
  article
}) => {
  // replace endpoint url
  let content: string = t('confirmation.body');

  // replace info email
  const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g;
  content = content.replace(
    emailRegex,
    '<u><a class="whitespace-nowrap" href="mailto:$1">$1</a></u>'
  );

  const phoneRegex = /\+\d+(-?\d+)*(\s-?\d+)*(-\d+)?(\b|$)/g;
  content = content.replace(
    phoneRegex,
    '<u class="whitespace-nowrap"><a href="tel:$&">$&</a></u>'
  );

  // replace help page
  if (article === 'minion') {
    content = content.replace(
      '%help',
      '<br/><u><a href="https://autosen.com" target="_blank" rel="noopener noreferrer">https://autosen.com</a></u><br/>' // change before launch (LD)
    );
    content = content.replaceAll('%device', 'minion');
  } else {
    content = content.replace(
      '%help',
      '<br/><u><a href="https://help.io-key.com" target="_blank" rel="noopener noreferrer">https://help.io-key.com</a></u><br/>'
    );
    content = content.replaceAll('%device', 'io-key');
  }

  const modalRef = useRef<HTMLDivElement>(null);
  function handleCloseModal() {
    setShowSuccessModalCumulocity(false);
  }
  const handleOutsideClick = (event: any) => {
    if (modalRef.current && !modalRef.current?.contains(event.target)) {
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (showSuccessModalCumulocity) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showSuccessModalCumulocity]);

  return (
    <Modal
      isOpen={showSuccessModalCumulocity}
      overlayClassName="Overlay"
      className="react-modal-success-cumulocity fixed flex justify-center items-center z-50 w-full h-full top-0 left-0 bg-[#354452]/60 outline-none"
      ariaHideApp={false}
      onRequestClose={handleCloseModal}
      overlayElement={(props: any, contentElement: any) => (
        <div {...props} onClick={handleOutsideClick}>
          {contentElement}
        </div>
      )}
    >
      <Animation className="flex justify-center items-center">
        <div className="w-[420px] max-w-[90%] bg-white" ref={modalRef}>
          <div className="relative uk-modal-header rounded-none bg-[#D9F2FF]">
            <button
              className="absolute right-4 p-2"
              type="button"
              onClick={handleCloseModal}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  fill="none"
                  stroke="#000"
                  strokeWidth="1.1"
                  x1="1"
                  y1="1"
                  x2="13"
                  y2="13"
                ></line>
                <line
                  fill="none"
                  stroke="#000"
                  strokeWidth="1.1"
                  x1="13"
                  y1="1"
                  x2="1"
                  y2="13"
                ></line>
              </svg>
            </button>
            <h2 className="uk-modal-title">{t('success_thankyou')}</h2>
          </div>
          <div
            className="uk-modal-body react-modalcontent rounded-none"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
          <div className="uk-modal-body pt-0 w-full flex justify-end">
            <button
              className="btn btn-lg btn-primary focus:bg-primary rounded-none text-white text-2xl uppercase m-0"
              onClick={handleCloseModal}
            >
              {t('success_close')}
            </button>
          </div>
        </div>
      </Animation>
    </Modal>
  );
};

export default SuccessModal;
