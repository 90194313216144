import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorModal from '../errorModal';
import SuccessModalApollo from './successModalApollo';
import RegisterForm from './registerForm';

// this file contains form and modals for creating / registrating a new apollo container

interface IRegistrationPage {
  currentLanguage: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  setShowSmallprintModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSmallprintModalText: React.Dispatch<React.SetStateAction<string>>;
  setSmallprintModalImageSrc: React.Dispatch<React.SetStateAction<string>>;
}

function RegistrationPage({
  currentLanguage,
  setShowSmallprintModal,
  setSmallprintModalText,
  setSmallprintModalImageSrc
}: IRegistrationPage) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccessModalApollo, setShowSuccessModalApollo] =
    useState<boolean>(false);

  //Error modal
  const [errorModalIsOpen, setErrorModalOpen] = useState<boolean>(false);
  const errorMessageHeading = t('errors.heading');
  const [errorMessageBody, setErrorMessageBody] = useState<string>('');

  // resgister form
  const [email, setEmail] = useState<string>('');
  const [customerNr, setCustomerNr] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [url, setUrl] = useState<string>('');

  // set page title
  useEffect(() => {
    document.title = t('form.title_apollo_signup');
  }, [currentLanguage, t]);

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      let res = await fetch('/api/v1/assignment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          language: currentLanguage,
          email,
          customerNr,
          firstName,
          lastName,
          phone,
          company,
          endpoint: url
        })
      });

      setLoading(false);
      if (!res.ok) {
        if (res.status === 400) {
          setErrorMessageBody(t('errors.form'));
          setErrorModalOpen(true);
        } else if (res.status === 304) {
          setErrorMessageBody(t('errors.url_already_in_use'));
          setErrorModalOpen(true);
        } else if (res.status === 500) {
          setErrorMessageBody(t('errors.500'));
          setErrorModalOpen(true);
        } else if (res.status === 404) {
          setErrorMessageBody(t('errors.apollo_id'));
          setErrorModalOpen(true);
        } else {
          setErrorMessageBody(t('errors.general'));
          setErrorModalOpen(true);
        }
      } else {
        setShowSuccessModalApollo(true);
      }
    } catch (error) {
      setLoading(false);
      setErrorMessageBody(t('errors.general'));
      setErrorModalOpen(true);
    }
  };

  return (
    <>
      <RegisterForm
        currentLanguage={currentLanguage}
        handleFormSubmit={handleFormSubmit}
        loading={loading}
        setShowSmallprintModal={setShowSmallprintModal}
        setSmallprintModalImageSrc={setSmallprintModalImageSrc}
        setSmallprintModalText={setSmallprintModalText}
        setEmail={setEmail}
        setCustomerNr={setCustomerNr}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setCompany={setCompany}
        setPhone={setPhone}
        setUrl={setUrl}
        url={url}
        email={email}
        customerNr={customerNr}
        firstName={firstName}
        lastName={lastName}
        company={company}
        phone={phone}
      />

      <ErrorModal
        errorModalIsOpen={errorModalIsOpen}
        setErrorModalOpen={setErrorModalOpen}
        t={t}
        errorMessageBody={errorMessageBody}
        errorMessageHeading={errorMessageHeading}
      />

      <SuccessModalApollo
        t={t}
        showSuccessModalApollo={showSuccessModalApollo}
        setShowSuccessModalApollo={setShowSuccessModalApollo}
      />
    </>
  );
}

export default RegistrationPage;
