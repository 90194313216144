import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FormInput from './formInput';
import LoadingSpinner from './loadingSpinner';
import { useState } from 'react';
import {
  validateUrl,
  validateEmail,
  validatePassword,
  validatePasswordConfirm,
  validateAcceptToc,
  validatePostcode,
  validatePhone
} from '../utils/validation';
import TocInput from './tocInput';
import { IApolloBody, ICumulocityBody } from '../App';
import RequiredInputField from './requiredInputField';
import SelectValidation from './selectValidation';

// appears if customer has entered an IMEI of a cumulocity device

interface IConctactDataForm {
  setSalutation: React.Dispatch<React.SetStateAction<string>>;
  setSalutationCode: React.Dispatch<
    React.SetStateAction<'male' | 'female' | 'other'>
  >;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setCompany: React.Dispatch<React.SetStateAction<string>>;
  setIndustry: React.Dispatch<React.SetStateAction<string>>;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  setStreet: React.Dispatch<React.SetStateAction<string>>;
  setPostcode: React.Dispatch<React.SetStateAction<string>>;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setPasswordConfirm: React.Dispatch<React.SetStateAction<string>>;
  setAcceptToc: React.Dispatch<React.SetStateAction<boolean>>;
  setAcceptNewsletter: React.Dispatch<React.SetStateAction<boolean>>;
  acceptToc: boolean;
  acceptNewsletter: boolean;
  handleFormSubmit: (body: IApolloBody | ICumulocityBody) => Promise<void>;
  loading: boolean;
  email: string;
  password: string;
  passwordConfirm: string;
  salutation: string;
  salutationCode: string;
  firstName: string;
  lastName: string;
  company: string;
  industry: string;
  phone: string;
  street: string;
  postcode: string;
  city: string;
  currentLanguage: string;
  url: string;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
  imei: string;
  customerId: string;
  handleNewsletterSubscription: () => Promise<void>;
  setShowSmallprintModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSmallprintModalText: React.Dispatch<React.SetStateAction<string>>;
  setSmallprintModalImageSrc: React.Dispatch<React.SetStateAction<string>>;
  customerIdValidated: boolean;
  imeiValidated: boolean;
  handleUrlCheck: (url: string) => Promise<boolean>;
}

const ContactDataForm: React.FC<IConctactDataForm> = ({
  imei,
  customerId,
  setSalutation,
  setSalutationCode,
  setFirstName,
  setLastName,
  setEmail,
  setCompany,
  setIndustry,
  setPhone,
  setStreet,
  setPostcode,
  setCity,
  setPassword,
  setPasswordConfirm,
  setAcceptToc,
  setAcceptNewsletter,
  acceptToc,
  acceptNewsletter,
  handleFormSubmit,
  loading,
  email,
  password,
  passwordConfirm,
  salutation,
  salutationCode,
  firstName,
  lastName,
  company,
  industry,
  phone,
  street,
  postcode,
  city,
  currentLanguage,
  setUrl,
  url,
  handleNewsletterSubscription,
  setShowSmallprintModal,
  setSmallprintModalText,
  setSmallprintModalImageSrc,
  customerIdValidated,
  imeiValidated,
  handleUrlCheck
}) => {
  const { t } = useTranslation();

  //Form validation state
  const [firstNameEmpty, setFirstNameEmpty] = useState<boolean>(false);
  const [lastNameEmpty, setLastNameEmpty] = useState<boolean>(false);
  const [companyEmpty, setCompanyEmpty] = useState<boolean>(false);
  const [phoneEmpty, setPhoneEmpty] = useState<boolean>(false);
  const [streetEmpty, setStreetEmpty] = useState<boolean>(false);
  const [cityEmpty, setCityEmpty] = useState<boolean>(false);
  const [urlEmpty, setUrlEmpty] = useState<boolean>(false);
  const [urlCheckMessage, setUrlCheckMessage] = useState<string>('');
  const [emailEmpty, setEmailEmpty] = useState<boolean>(false);
  const [postcodeEmpty, setPostcodeEmpty] = useState<boolean>(false);
  const [passwordEmpty, setPasswordEmpty] = useState<boolean>(false);
  const [passwordConfirmEmpty, setPasswordConfirmEmpty] =
    useState<boolean>(false);

  const [salutationValidated, setSalutationValidated] = useState<boolean>(true);
  const [industryValidated, setIndustryValidated] = useState<boolean>(true);
  const [urlValidated, setUrlValidated] = useState<boolean>(true);
  const [emailValidated, setEmailValidated] = useState<boolean>(true);
  const [postcodeValidated, setPostcodeValidated] = useState<boolean>(true);
  const [passwordValidated, setPasswordValidated] = useState<boolean>(true);
  const [passwordConfirmValidated, setPasswordConfirmValidated] =
    useState<boolean>(true);
  const [tocValidated, setTocValidated] = useState<boolean>(true);
  const [contactFormValidated, setContactFormValidated] =
    useState<boolean>(true);
  const [phoneValidated, setPhoneValidated] = useState<boolean>(true);
  const [formFirstRender, setFormFirstRender] = useState<boolean>(true);

  const industryOptions = [t('form.industry.label')].concat(
    t('form.industry.options', {
      returnObjects: true
    })
  );

  const salutationOptions = [
    t('form.salutation.label'),
    t('form.salutation.mrs'),
    t('form.salutation.mr')
  ];

  const handleFormValidation = useCallback(() => {
    setFormFirstRender(false);
    let formValid = true;

    let tocValid: boolean = true;
    let urlValid: boolean = true;
    let emailValid: boolean = true;
    let passwordValid: boolean = true;
    let postcodeValid: boolean = true;
    let passwordConfirmValid: boolean = true;
    let phoneValid = true;

    if (!acceptToc) {
      setTocValidated(false);
      formValid = false;
    }

    if (salutation.length < 1) {
      setSalutationValidated(false);
      formValid = false;
    }

    if (industry.length < 1) {
      setIndustryValidated(false);
      formValid = false;
    }

    if (urlCheckMessage !== '') formValid = false;

    if (
      email &&
      password &&
      passwordConfirm &&
      acceptToc &&
      postcode &&
      url &&
      phone
    ) {
      // Check if inputs are validated
      tocValid = validateAcceptToc(acceptToc).validated;
      setTocValidated(tocValid);

      urlValid = validateUrl(url).validated;
      setUrlValidated(urlValid);

      emailValid = validateEmail(email).validated;
      setEmailValidated(emailValid);

      passwordValid = validatePassword(password).validated;
      setPasswordValidated(passwordValid);

      postcodeValid = validatePostcode(postcode).validated;
      setPostcodeValidated(postcodeValid);

      passwordConfirmValid = validatePasswordConfirm({
        password,
        passwordConfirm
      }).validated;
      setPasswordConfirmValidated(passwordConfirmValid);

      phoneValid = validatePhone(phone).validated;
      setPhoneValidated(phoneValid);
    } else {
      if (!email) setEmailEmpty(true);
      if (!password) setPasswordEmpty(true);
      if (!passwordConfirm) setPasswordConfirmEmpty(true);
      if (!url) setUrlEmpty(true);
      if (!postcode) setPostcodeEmpty(true);
      if (!firstName) setFirstNameEmpty(true);
      if (!lastName) setLastNameEmpty(true);
      if (!company) setCompanyEmpty(true);
      if (!phone) setPhoneEmpty(true);
      if (!street) setStreetEmpty(true);
      if (!city) setCityEmpty(true);

      setContactFormValidated(false);
      formValid = false;
    }

    // If something was not validated or is missing, set global validation to false
    if (
      !tocValid ||
      !passwordValid ||
      !passwordConfirmValid ||
      !postcodeValid ||
      !phoneValid ||
      !urlValid ||
      !emailValid ||
      !salutation ||
      !firstName ||
      !lastName ||
      !industry ||
      !company ||
      !phone ||
      !street ||
      !city ||
      !acceptToc ||
      !imeiValidated ||
      !customerIdValidated
    ) {
      formValid = false;
    }

    if (formValid === false) {
      setContactFormValidated(false);
      return false;
    } else {
      setContactFormValidated(true);
      return true;
    }
  }, [
    acceptToc,
    salutation,
    industry,
    urlCheckMessage,
    email,
    password,
    passwordConfirm,
    postcode,
    url,
    phone,
    firstName,
    lastName,
    company,
    street,
    city,
    imeiValidated,
    customerIdValidated
  ]);

  const handleFormSubmission = (e: any) => {
    e.preventDefault();
    const formValid = handleFormValidation();
    if (formValid === true) {
      const body: ICumulocityBody = {
        imei: imei,
        customerId: customerId,
        domain: url,
        email: email,
        salutation: salutation,
        salutationCode: salutationCode,
        firstName: firstName,
        lastName: lastName,
        company: company,
        phone: phone,
        street: street,
        postcode: postcode,
        city: city,
        industry: industry,
        password: password,
        language: currentLanguage
      };

      handleFormSubmit(body);

      if (acceptNewsletter) {
        handleNewsletterSubscription();
      }
    }
  };

  useEffect(() => {
    if (!formFirstRender) handleFormValidation();
  }, [
    email,
    password,
    passwordConfirm,
    acceptToc,
    acceptNewsletter,
    postcode,
    url,
    phone,
    salutation,
    firstName,
    lastName,
    company,
    industry,
    street,
    city,
    urlCheckMessage,
    formFirstRender,
    handleFormValidation
  ]);

  // if starterForm loses validation, then set validation false for contact form. if it regains validation, validate the whole contact form
  // recheck validation if entered values change
  useEffect(() => {
    if (!customerIdValidated || !imeiValidated) setContactFormValidated(false);
    else if (customerIdValidated && imeiValidated && !formFirstRender)
      handleFormValidation();
  }, [
    customerIdValidated,
    imeiValidated,
    formFirstRender,
    handleFormValidation,
    setContactFormValidated
  ]);

  return (
    <form id="contactForm">
      <div className="w-full grid grid-cols-12 gap-4 p-6">
        <hr className="col-span-12 my-12 w-[80%] relative m-auto left-0 right-0" />

        <label htmlFor="form-url" className="col-span-12">
          {t('form.url.label')}
          <div
            className="cursor-pointer"
            onClick={() => {
              setShowSmallprintModal(true);
              setSmallprintModalText(t('form.url.smallprint'));
              setSmallprintModalImageSrc(
                `/img/lang/` + currentLanguage + '/url.jpg'
              );
            }}
          >
            <small className="uk-text-muted">{t('form.url.smallprint')}</small>
          </div>
        </label>

        <div className="sm:join join-horizontal col-span-12 grid grid-cols-subgrid gap-0">
          <FormInput
            isEmpty={urlEmpty}
            setEmpty={setUrlEmpty}
            validationFct={validateUrl}
            input={url}
            id="form-url"
            name="url"
            type="text"
            className="uk-input
                  as-input
                  uk-form-large
                  uk-text-center
                  uk-background-muted 
                  join-item
                  m-0
              "
            wrapperClassName="col-span-12 sm:col-span-8 w-full"
            autoComplete="off"
            required
            placeholder={t('form.url.placeholder')}
            maxLength={20}
            pattern="[a-z][a-zA-Z0-9-]*"
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setUrl(e.currentTarget.value)
            }
            onBlur={async () => {
              if (url.length > 0) {
                const tenantExists = await handleUrlCheck(url);
                if (tenantExists) {
                  setUrlCheckMessage(t('form.url.urlTaken')); // (LD)
                } else setUrlCheckMessage('');
              } else setUrlCheckMessage('');
            }}
            isValidated={urlValidated}
            setValidated={setUrlValidated}
            message={urlCheckMessage}
          />

          <div className="join-item col-span-12 sm:col-span-4">
            <input
              type="text"
              className="
                uk-input
                uk-text-center
                uk-form-large
                join-item
              "
              value=".autosen.cloud"
              readOnly
              disabled
            />
          </div>
        </div>

        <hr className="col-span-12 my-12 w-[80%] relative m-auto left-0 right-0" />

        <h3 className="col-span-8">
          <b>{t('form.title_credentials')}</b>
        </h3>

        <div className="col-span-4 text-right">{t('form.required_field')}</div>

        <SelectValidation
          setValue={setSalutation}
          options={salutationOptions}
          isValid={salutationValidated}
          onBlur={(e: any) => {
            setSalutationValidated(salutation.length < 1 ? false : true);
            setSalutationCode(
              e.target.value === t('form.salutation.mrs')
                ? 'female'
                : e.target.value === t('form.salutation.mr')
                ? 'male'
                : 'other'
            );
          }}
          className="col-span-12 sm:col-span-4"
          name="salutation"
        />

        <RequiredInputField
          setEmpty={setFirstNameEmpty}
          isEmpty={firstNameEmpty}
          input={firstName}
          name="firstName"
          type="text"
          className="
            uk-input
            as-input
            uk-form-large
            uk-background-muted
          "
          placeholder={t('form.first_name')}
          wrapperClassName="col-span-12 sm:col-span-6 sm:col-start-1"
          autoComplete="off"
          required
          maxLength={20}
          pattern="[a-zA-Z]*"
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setFirstName(e.currentTarget.value)
          }
        />

        <RequiredInputField
          input={lastName}
          setEmpty={setLastNameEmpty}
          isEmpty={lastNameEmpty}
          name="lastName"
          type="text"
          className="
            uk-input
            as-input
            uk-form-large
            uk-background-muted
          "
          wrapperClassName="col-span-12 sm:col-span-6"
          placeholder={t('form.last_name')}
          autoComplete="off"
          required
          maxLength={20}
          pattern="[a-zA-Z]*"
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setLastName(e.currentTarget.value)
          }
        />

        <label htmlFor="email" className="col-span-12 -mb-3">
          <div>
            <small
              className="uk-text-muted"
              style={{ textDecoration: 'none !important' }}
            >
              {t('form.email.smallprint')}
            </small>
          </div>
        </label>
        <FormInput
          setEmpty={setEmailEmpty}
          isEmpty={emailEmpty}
          input={email}
          isValidated={emailValidated}
          setValidated={setEmailValidated}
          validationFct={validateEmail}
          style={{ fontWeight: '500', letterSpacing: '.6px' }}
          name="email"
          type="email"
          className="
              uk-input
              as-input
              uk-form-large
              uk-background-muted
            "
          wrapperClassName="col-span-12"
          placeholder={t('form.email.placeholder')}
          autoComplete="off"
          required
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setEmail(e.currentTarget.value)
          }
        />

        <FormInput
          isValidated={phoneValidated}
          setValidated={setPhoneValidated}
          validationFct={validatePhone}
          input={phone}
          setEmpty={setPhoneEmpty}
          isEmpty={phoneEmpty}
          name="phone"
          type="tel"
          className="
              uk-input
              as-input
              uk-form-large
              uk-background-muted
            "
          wrapperClassName="col-span-12"
          placeholder={t('form.phone')}
          autoComplete="off"
          required
          maxLength={20}
          pattern="[+](([(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[\s\.]?[(]?[0-9]{1,3}[)]?([\s\.]?[0-9]{3})([\s\.]?[0-9]{3,4})"
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setPhone(e.currentTarget.value)
          }
        />

        <RequiredInputField
          input={street}
          setEmpty={setStreetEmpty}
          isEmpty={streetEmpty}
          name="street"
          type="text"
          className="
              uk-input
              as-input
              uk-form-large
              uk-background-muted
            "
          wrapperClassName="col-span-12"
          placeholder={t('form.street')}
          autoComplete="off"
          required
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setStreet(e.currentTarget.value)
          }
          maxLength={200}
        />

        <RequiredInputField
          input={city}
          isEmpty={cityEmpty}
          setEmpty={setCityEmpty}
          name="city"
          type="text"
          className="
              uk-input
              as-input
              uk-form-large
              uk-background-muted
            "
          wrapperClassName="col-span-12 sm:col-span-8"
          placeholder={t('form.city')}
          autoComplete="off"
          required
          maxLength={50}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setCity(e.currentTarget.value)
          }
        />

        <FormInput
          setEmpty={setPostcodeEmpty}
          isEmpty={postcodeEmpty}
          validationFct={validatePostcode}
          isValidated={postcodeValidated}
          setValidated={setPostcodeValidated}
          input={postcode}
          name="postcode"
          type="text"
          className="
              uk-input
              as-input
              uk-form-large
              uk-background-muted
            "
          wrapperClassName="col-span-12 sm:col-span-4"
          placeholder={t('form.postcode')}
          autoComplete="off"
          required
          maxLength={20}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setPostcode(e.currentTarget.value)
          }
        />

        <RequiredInputField
          input={company}
          setEmpty={setCompanyEmpty}
          isEmpty={companyEmpty}
          name="company"
          type="text"
          className="
              uk-input
              as-input
              uk-form-large
              uk-background-muted
            "
          wrapperClassName="col-span-12 sm:col-span-8"
          placeholder={t('form.company')}
          autoComplete="off"
          maxLength={256}
          required
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setCompany(e.currentTarget.value)
          }
        />

        <SelectValidation
          options={industryOptions}
          setValue={setIndustry}
          fullWidth
          isValid={industryValidated}
          className="col-span-12 sm:col-span-4"
          onBlur={() =>
            setIndustryValidated(industry.length < 1 ? false : true)
          }
          name="industry"
        />

        <FormInput
          setEmpty={setPasswordEmpty}
          isEmpty={passwordEmpty}
          validationFct={validatePassword}
          isValidated={passwordValidated}
          setValidated={setPasswordValidated}
          input={password}
          id="form-password"
          name="password"
          type="password"
          autoComplete="new-password"
          className="
            uk-input
            as-input
            uk-form-large
            uk-background-muted
          "
          wrapperClassName="col-span-12 sm:col-span-6"
          placeholder={t('form.password')}
          required
          minLength={6}
          maxLength={20}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setPassword(e.currentTarget.value)
          }
        />

        <FormInput
          setEmpty={setPasswordConfirmEmpty}
          isEmpty={passwordConfirmEmpty}
          input={passwordConfirm}
          passwordInput={password}
          isValidated={passwordConfirmValidated}
          setValidated={setPasswordConfirmValidated}
          validationFct={validatePasswordConfirm}
          type="password"
          autoComplete="new-password"
          className="
            uk-input
            as-input
            uk-form-large
            uk-background-muted
          "
          wrapperClassName="col-span-12 sm:col-span-6"
          placeholder={t('form.password_confirmation')}
          required
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setPasswordConfirm(e.currentTarget.value)
          }
        />

        <TocInput
          isValidated={tocValidated}
          setValidated={setTocValidated}
          input={acceptToc}
          validationFct={validateAcceptToc}
          name="acceptToc"
          type="checkbox"
          className="
            uk-checkbox
            as-checkbox-primary
            uk-background-muted
            col-span-2
            w-14
            h-14
          "
          wrapperClassName="col-span-12 grid grid-cols-subgrid place-items-center"
          labelClassName="col-span-10 w-full"
          required
          onChange={(e: any) => {
            setAcceptToc(e.currentTarget.checked);
          }}
        />

        <input
          name="acceptNewsletter"
          type="checkbox"
          className="
          uk-checkbox
          as-checkbox-primary
          uk-form-large 
          uk-background-muted
        "
          required
          //checked
          defaultChecked
          hidden
        />

        <label className="col-span-12 grid grid-cols-subgrid place-items-center">
          <input
            name="acceptPrivacy"
            type="checkbox"
            className="
            uk-checkbox
            as-checkbox-primary
            uk-background-muted
            col-span-2
            w-14
            h-14
          "
            onChange={() => setAcceptNewsletter(!acceptNewsletter)}
          />
          <div
            className="text col-span-10"
            dangerouslySetInnerHTML={{
              __html: t('form.accept_privacy')
            }}
          ></div>
        </label>
        <div className="col-span-12 flex flex-col justify-center items-start w-full mt-12">
          {contactFormValidated ? (
            <div></div>
          ) : (
            <label className="uk-text-danger mb-4" style={{ fontSize: '90%' }}>
              {t('errors.form')}
            </label>
          )}

          <div>
            {loading ? (
              <div>
                <label style={{ fontSize: '80%' }}>{t('form.lineone')}</label>
                <label style={{ fontSize: '80%' }}>{t('form.linetwo')}</label>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <button
            type="submit"
            className="btn p-4 btn-lg btn-primary focus:bg-primary rounded-none text-white text-2xl uppercase as-hide-if-loading"
            onClick={(e) => handleFormSubmission(e)}
          >
            {loading && <LoadingSpinner />}
            {t('form.submit')}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ContactDataForm;
