import React from 'react';
import i18next from 'i18next';

// checkbox input

interface ITocInput {
  validationFct: (any: any) => {
    validated: boolean;
    message: string;
  };
  rest?: any;
  input: any;
  props?: any;
  id?: string;
  name?: string;
  type?: string;
  className?: string;
  placeholder?: string;
  autoComplete?: string;
  required?: boolean;
  pattern?: string;
  maxLength?: any;
  onChange?: any;
  isValidated: boolean;
  setValidated: React.Dispatch<React.SetStateAction<boolean>>;
  children?: any;
  minLength?: any;
  style?: any;
  wrapperClassName?: string;
  labelClassName?: string;
}

const TocInput: React.FC<ITocInput> & React.HTMLAttributes<any> = ({
  validationFct,
  input,
  id,
  name,
  type,
  className,
  placeholder,
  autoComplete,
  required,
  pattern,
  maxLength,
  onChange,
  isValidated,
  setValidated,
  minLength,
  style,
  children,
  wrapperClassName,
  labelClassName,
  ...props
}) => {
  return (
    <label className={`uk-flex uk-flex-middle ${wrapperClassName}`}>
      <input
        onBlur={(e) => {
          if (input) {
            setValidated(validationFct(input).validated);
          }
        }}
        id={id}
        name={name}
        type={type}
        className={className}
        placeholder={placeholder}
        autoComplete={autoComplete}
        required={required}
        maxLength={maxLength}
        pattern={pattern}
        onChange={onChange}
        minLength={minLength}
        style={style}
      />
      <div className={labelClassName}>
        <div
          className="text"
          dangerouslySetInnerHTML={{
            __html: i18next.t('form.accept_toc')
          }}
        ></div>
        <div>
          {isValidated ? (
            <></>
          ) : (
            <label className="uk-text-danger" style={{ fontSize: '80%' }}>
              {validationFct(input).message}
            </label>
          )}
        </div>
      </div>
    </label>
  );
};

export default TocInput;
