import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmLogic from './confirmLogic';
import { useSearchParams } from 'react-router-dom';

// this file contains confirm page needed for confirming apollo activation

interface IConfirmPage {
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  currentLanguage: string;
}

function ConfirmPage({ setLanguage, currentLanguage }: IConfirmPage) {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // set page title
  useEffect(() => {
    document.title = t('form.title_apollo');
  }, [currentLanguage, t]);

  useEffect(() => {
    const handlePageLoad = async () => {
      const language = searchParams.get('language');

      if (language) {
        setLanguage(language);
        i18n.changeLanguage(language);
      }

      const token = searchParams.get('token');

      if (token) {
        try {
          setLoading(true);
          let res = await fetch('/api/v1/confirm', {
            method: 'POST',
            headers: new Headers({
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json'
            })
          });
          setLoading(false);
          console.log(res);
          if (!res.ok) {
            if (
              res.status === 401 ||
              res.status === 500 ||
              res.status === 404
            ) {
              setSuccess(false);
            }
          } else {
            setSuccess(true);
          }
        } catch (error) {
          setLoading(false);
          setSuccess(false);
          console.log(error);
        }
      }
    };
    handlePageLoad();
  }, [i18n, searchParams, setLanguage]);

  return (
    <div className="w-full flex justify-center items-center flex-col p-6">
      <ConfirmLogic
        t={t}
        success={success}
        loading={loading}
        heading={t('apollo.confirm.heading')}
        errorHeading={t('apollo.errors.heading')}
        errorText={t('apollo.confirm.errorText')}
      />
      <a
        href="/"
        type="submit"
        className="btn p-4btn btn-lg btn-primary focus:bg-primary rounded-none text-white text-2xl uppercase"
      >
        <p className="text-2xl">{t('apollo.confirm.button')}</p>
      </a>
    </div>
  );
}

export default ConfirmPage;
