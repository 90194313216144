import React from 'react';
import { useTranslation } from 'react-i18next';
import Animation from '../animations';
import { Link } from 'react-router-dom';

interface INavBar {
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  currentLanguage?: String;
  isScrolled?: Boolean;
}

const NavBar: React.FC<INavBar> = ({
  setLanguage,
  currentLanguage,
  isScrolled
}) => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    code: string
  ) => {
    e.preventDefault();
    setLanguage(code);
    i18n.changeLanguage(code);
  };

  const getLanguageImageLink = () => {
    return `/img/lang/` + currentLanguage + '/flag.png';
  };

  return (
    <div
      className={`navbar py-3 px-4 md:px-12 m-0 z-50 sticky top-0 transition-all ${
        isScrolled && 'shadow-lg backdrop-blur-md bg-white/30'
      }`}
    >
      <div className="flex-1 mx-4">
        <Animation dir="right">
          <Link to="/">
            <img alt="autosen cloud and apollo logo" src="/img/logo.png" />
          </Link>
        </Animation>
      </div>
      <div className="flex-none gap-4 md:gap-12">
        {isScrolled && (
          <div className="dropdown dropdown-end">
            <button
              className="btn btn-lg rounded-none btn-primary focus:bg-primary text-white text-2xl uppercase"
              onClick={() => {
                document.getElementById('register')?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'center'
                });
              }}
            >
              {t('hero.activate')}
            </button>
          </div>
        )}
        <div className="dropdown dropdown-end">
          <Animation dir="left">
            <div
              tabIndex={0}
              role="button"
              className="btn btn-ghost btn-circle avatar border-none"
            >
              <div className="w-10 rounded-full">
                <img alt="current language" src={getLanguageImageLink()} />
              </div>
            </div>
          </Animation>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content mt-8 z-[1] p-2 shadow bg-white bg-opacity-90 w-52"
          >
            <li className="flex flex-row items-center group">
              <button
                onClick={(e) => handleLanguageChange(e, 'en')}
                style={{ background: 'none', border: 'none' }}
              >
                <img
                  className="rounded-full h-12 w-12 group-hover:scale-105 transition-all"
                  src="/img/lang/en/flag.png"
                  alt="English"
                />
                <span
                  className={`text-xl ${
                    currentLanguage === 'en' && 'text-neutral font-bold'
                  }`}
                >
                  English
                </span>
              </button>
            </li>
            <li className="flex flex-row items-center group">
              <button
                style={{ background: 'none', border: 'none' }}
                onClick={(e) => handleLanguageChange(e, 'de')}
                data-test="lang-de"
              >
                <img
                  className="rounded-full h-12 w-12 group-hover:scale-105 transition-all"
                  src="/img/lang/de/flag.png"
                  alt="Deutsch"
                />
                <span
                  className={`text-xl ${
                    currentLanguage === 'de' && 'text-neutral font-bold'
                  }`}
                >
                  Deutsch
                </span>
              </button>
            </li>
            <li className="flex flex-row items-center group">
              <button
                style={{ background: 'none', border: 'none' }}
                onClick={(e) => handleLanguageChange(e, 'fr')}
              >
                <img
                  className="rounded-full h-12 w-12 group-hover:scale-105 transition-all"
                  src="/img/lang/fr/flag.png"
                  alt="Français"
                />
                <span
                  className={`text-xl ${
                    currentLanguage === 'fr' && 'text-neutral font-bold'
                  }`}
                >
                  Français
                </span>
              </button>
            </li>
            <li className="flex flex-row items-center group">
              <button
                style={{ background: 'none', border: 'none' }}
                onClick={(e) => handleLanguageChange(e, 'it')}
              >
                <img
                  className="rounded-full h-12 w-12 group-hover:scale-105 transition-all"
                  src="/img/lang/it/flag.png"
                  alt="Italiano"
                />
                <span
                  className={`text-xl ${
                    currentLanguage === 'it' && 'text-neutral font-bold'
                  }`}
                >
                  Italiano
                </span>
              </button>
            </li>
            <li className="flex flex-row items-center group">
              <button
                onClick={(e) => handleLanguageChange(e, 'cz')}
                style={{ background: 'none', border: 'none' }}
              >
                <img
                  className="rounded-full h-12 w-12 group-hover:scale-105 transition-all"
                  src="/img/lang/cz/flag.png"
                  alt="Česky"
                />
                <span
                  className={`text-xl ${
                    currentLanguage === 'cz' && 'text-neutral font-bold'
                  }`}
                >
                  Česky
                </span>
              </button>
            </li>
            <li className="flex flex-row items-center group">
              <button
                onClick={(e) => handleLanguageChange(e, 'nl')}
                style={{ background: 'none', border: 'none' }}
              >
                <img
                  className="rounded-full h-12 w-12 group-hover:scale-105 transition-all"
                  src="/img/lang/nl/flag.png"
                  alt="Nederlands"
                />
                <span
                  className={`text-xl ${
                    currentLanguage === 'nl' && 'text-neutral font-bold'
                  }`}
                >
                  Nederlands
                </span>
              </button>
            </li>
            <li className="flex flex-row items-center group">
              <button
                onClick={(e) => handleLanguageChange(e, 'es')}
                style={{ background: 'none', border: 'none' }}
              >
                <img
                  className="rounded-full h-12 w-12 group-hover:scale-105 transition-all"
                  src="/img/lang/es/flag.png"
                  alt="Español"
                />
                <span
                  className={`text-xl ${
                    currentLanguage === 'es' && 'text-neutral font-bold'
                  }`}
                >
                  Español
                </span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
