import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NavBar from './components/ui/navbar';
import StarterForm from './components/starterForm';
import ErrorModal from './components/errorModal';
import SuccessModalApollo from './components/successModalApollo';
import SuccessModalCumulocity from './components/successModalCumulocity';
import ImprintModal from './components/imprintModal';
import FormLogic from './components/formLogic';
import Step from './components/step';
import RegistrationPage from './components/registration/registrationPage';
import ActivateApolloPage from './components/registration/activateApolloPage';
import ConfirmPage from './components/registration/confirmPage';
import SmallprintModal from './components/smallprintModal';
import Animation from './components/animations';
import ConfirmSignupPage from './components/registration/confirmSignupPage';

export interface IApolloBody {
  imei: String;
  customerId: String;
  domain: String;
  apolloId: String;
  language: String;
}

export interface ICumulocityBody {
  imei: string;
  customerId: string;
  domain: string;
  email: string;
  salutation: string;
  salutationCode: string;
  firstName: string;
  lastName: string;
  company: string;
  phone: string;
  street: string;
  postcode: string;
  city: string;
  industry: string;
  password: string;
  language: string;
}

function App() {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setLanguage] = useState<string>('en');
  const [imprintIsOpen, setImprintOpen] = useState<boolean>(false);
  const [imeiCheckSuccessfull, setImeiCheckSuccessfull] =
    useState<boolean>(false);

  useEffect(() => {
    const browserLanguage = navigator.language.trim().split(/-|_/)[0];
    const possibleLanguages = ['en', 'es', 'cz', 'de', 'nl', 'fr', 'it'];
    if (possibleLanguages.includes(browserLanguage)) {
      setLanguage(browserLanguage);
      i18n.changeLanguage(browserLanguage);
    }
  }, [i18n]);

  const [type, setType] = useState<string | null>('initial'); // initial, apollo or cumulocity
  const [article, setArticle] = useState<string | null>('io-key');
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccessModalApollo, setShowSuccessModalApollo] =
    useState<boolean>(false);
  const [showSuccessModalCumulocity, setShowSuccessModalCumulocity] =
    useState<boolean>(false);
  const [showSmallprintModal, setShowSmallprintModal] =
    useState<boolean>(false);
  const [smallprintModalText, setSmallprintModalText] = useState<string>('');
  const [smallprintModalImageSrc, setSmallprintModalImageSrc] =
    useState<string>('');
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  // error modal
  const [errorModalIsOpen, setErrorModalOpen] = useState<boolean>(false);
  const errorMessageHeading = t('errors.heading');
  const [errorMessageBody, setErrorMessageBody] = useState<string>('');

  // state starter form
  const [url, setUrl] = useState<string>('');
  const [imei, setImei] = useState<string>('');
  const [customerNr, setCustomerNr] = useState<string>('');

  // state contact form
  const [salutation, setSalutation] = useState<string>('');
  const [salutationCode, setSalutationCode] = useState<
    'male' | 'female' | 'other'
  >('other');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [industry, setIndustry] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [street, setStreet] = useState<string>('');
  const [postcode, setPostcode] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [acceptToc, setAcceptToc] = useState<boolean>(false);
  const [acceptNewsletter, setAcceptNewsletter] = useState<boolean>(false);
  const [customerNrValidated, setCustomerNrValidated] = useState<boolean>(true);
  const [imeiValidated, setImeiValidated] = useState<boolean>(true);

  // state apollo form
  const [apolloId, setApolloId] = useState<string>('');

  const resetForm = () => {
    setType('initial');
    setImei('');
    setImeiCheckSuccessfull(false);
    setCustomerNr('');
    setSalutation('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setCompany('');
    setIndustry('');
    setPhone('');
    setStreet('');
    setPostcode('');
    setCity('');
    setPassword('');
    setPasswordConfirm('');
    setAcceptToc(false);
    setAcceptNewsletter(false);
    setCustomerNrValidated(true);
    setImeiValidated(true);
  };

  // newsletter subscription
  const handleNewsletterSubscription = async () => {
    try {
      if (process.env.REACT_APP_NEWS_URL) {
        let res = await fetch(
          process.env.REACT_APP_NEWS_URL +
            '/api/v3.3/subscribers/listid.xmljson',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              EmailAddress: email,
              Name: firstName + ' ' + lastName,
              Resubscribe: false,
              RestartSubscriptionBasedAutoresponders: false,
              ConsentToTrack: 'No'
            })
          }
        );

        if (!res.ok) {
          console.error('Error subscribing to Newsletter');
          console.error('Response', res);
        } else {
          console.log('Successfully subscribed to Newsletter');
        }
      }
    } catch (error) {
      console.error('Error subscribing to Newsletter');
      console.error(error);
    }
  };

  // check imei if exists. return article type (minion / io-key) and device type (cumulocity / apollo)
  const handleImeiCheck = async (imei: string) => {
    setLoading(true);
    try {
      let res = await fetch(
        `/api/v1/deviceType?imei=` + imei + '&customerId=' + customerNr
      );

      setLoading(false);
      if (!res.ok) {
        if (res.status === 400) {
          setErrorMessageBody(t('errors.form'));
          setErrorModalOpen(true);
        } else if (res.status === 500) {
          setErrorMessageBody(t('errors.500'));
          setErrorModalOpen(true);
        } else {
          setErrorMessageBody(t('errors.general'));
          setErrorModalOpen(true);
        }
      } else {
        const data = await res.json();
        setType(data.type);
        setArticle(data.articleNumber?.startsWith('MN') ? 'minion' : 'io-key');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setErrorMessageBody(t('errors.general'));
      setErrorModalOpen(true);
    }
  };

  // check if cumulocity-url is already in use
  const handleUrlCheck = async (url: string) => {
    setLoading(true);
    try {
      let res = await fetch(`/api/v1/tenant?url=${url}`, {
        method: 'GET'
      });
      setLoading(false);
      if (!res.ok) {
        if (res.status === 400) {
          setErrorMessageBody(t('errors.form'));
          setErrorModalOpen(true);
        } else if (res.status === 500) {
          setErrorMessageBody(t('errors.500'));
          setErrorModalOpen(true);
        } else {
          setErrorMessageBody(t('errors.general'));
          setErrorModalOpen(true);
        }
      } else {
        const data = await res.json();
        return data.tenantExists;
      }
    } catch (error) {
      setLoading(false);
      setErrorMessageBody(t('errors.general'));
      setErrorModalOpen(true);
    }
  };

  // activate a device
  const handleFormSubmit = async (body: IApolloBody | ICumulocityBody) => {
    setLoading(true);
    try {
      let res = await fetch('/api/v1/provision', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      setLoading(false);
      if (res.status === 299) {
        setShowSuccessModalCumulocity(true);
        resetForm();
      } else if (!res.ok) {
        if (res.status === 400) {
          setErrorMessageBody(t('errors.form'));
          setErrorModalOpen(true);
        } else if (res.status === 500) {
          setErrorMessageBody(t('errors.500'));
          setErrorModalOpen(true);
        } else if (res.status === 404) {
          setErrorMessageBody(
            type === 'apollo' ? t('errors.apollo_id') : t('errors.customer_id')
          );
          setErrorModalOpen(true);
        } else if (res.status === 409) {
          setErrorMessageBody(
            type === 'apollo'
              ? t('errors.apollo_id_in_use')
              : t('errors.customer_id_in_use')
          );
          setErrorModalOpen(true);
        } else {
          setErrorMessageBody(t('errors.general'));
          setErrorModalOpen(true);
        }
      } else {
        const setupElement = document.getElementById('setup');
        if (setupElement) {
          setupElement.scrollIntoView({ behavior: 'smooth' });
        }
        //@ts-ignore
        if (body.firstName) {
          setShowSuccessModalCumulocity(true);
          resetForm();
        } else {
          setShowSuccessModalApollo(true);
        }
        // delete imei if apollo iokey so that a new one can be registered
        if (type === 'apollo') {
          setImei('');
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setErrorMessageBody(t('errors.general'));
      setErrorModalOpen(true);
    }
  };

  // for navbar effect
  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 0;
      setIsScrolled(scrolled);
    };
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Router>
      <NavBar
        setLanguage={setLanguage}
        currentLanguage={currentLanguage}
        isScrolled={isScrolled}
      />
      <Animation>
        <div
          id="register"
          className="w-full flex flex-row justify-center items-center z-1 min-h-[80vh] relative"
        >
          <div className="bg-white/80 w-11/12 max-w-[700px] my-12 join join-vertical rounded-none">
            <div className="card shadow-xl h-fit join-item join-horizontal z-2">
              <Routes>
                <Route
                  path="/"
                  element={
                    <Fragment>
                      <StarterForm
                        currentLanguage={currentLanguage}
                        setImei={setImei}
                        setCustomerNr={setCustomerNr}
                        handleImeiCheck={handleImeiCheck}
                        imei={imei}
                        loading={loading}
                        customerNr={customerNr}
                        checkSuccessfull={imeiCheckSuccessfull}
                        setShowSmallprintModal={setShowSmallprintModal}
                        setSmallprintModalImageSrc={setSmallprintModalImageSrc}
                        setSmallprintModalText={setSmallprintModalText}
                        setImeiValidated={setImeiValidated}
                        setCustomerNrValidated={setCustomerNrValidated}
                        imeiValidated={imeiValidated}
                        customerNrValidated={customerNrValidated}
                      />
                      <FormLogic
                        handleNewsletterSubscription={
                          handleNewsletterSubscription
                        }
                        imei={imei}
                        customerId={customerNr}
                        email={email}
                        password={password}
                        passwordConfirm={passwordConfirm}
                        loading={loading}
                        setSalutation={setSalutation}
                        setSalutationCode={setSalutationCode}
                        setFirstName={setFirstName}
                        setLastName={setLastName}
                        setEmail={setEmail}
                        setCompany={setCompany}
                        setIndustry={setIndustry}
                        setPhone={setPhone}
                        setStreet={setStreet}
                        setPostcode={setPostcode}
                        setCity={setCity}
                        setPassword={setPassword}
                        setPasswordConfirm={setPasswordConfirm}
                        setAcceptToc={setAcceptToc}
                        setAcceptNewsletter={setAcceptNewsletter}
                        acceptToc={acceptToc}
                        acceptNewsletter={acceptNewsletter}
                        type={type}
                        setErrorModalOpen={setErrorModalOpen}
                        setErrorMessageBody={setErrorMessageBody}
                        t={t}
                        setType={setType}
                        setImeiCheckSuccessfull={setImeiCheckSuccessfull}
                        setApolloId={setApolloId}
                        handleFormSubmit={handleFormSubmit}
                        apolloId={apolloId}
                        salutation={salutation}
                        salutationCode={salutationCode}
                        firstName={firstName}
                        lastName={lastName}
                        company={company}
                        industry={industry}
                        phone={phone}
                        street={street}
                        postcode={postcode}
                        city={city}
                        currentLanguage={currentLanguage}
                        url={url}
                        setUrl={setUrl}
                        setShowSmallprintModal={setShowSmallprintModal}
                        setSmallprintModalImageSrc={setSmallprintModalImageSrc}
                        setSmallprintModalText={setSmallprintModalText}
                        customerIdValidated={customerNrValidated}
                        imeiValidated={imeiValidated}
                        handleUrlCheck={handleUrlCheck}
                      />
                    </Fragment>
                  }
                />
                <Route
                  path="/apollo/register/confirm"
                  element={
                    <ConfirmSignupPage
                      setLanguage={setLanguage}
                      currentLanguage={currentLanguage}
                    />
                  }
                />
                <Route
                  path="/apollo/register"
                  element={
                    <RegistrationPage
                      setLanguage={setLanguage}
                      currentLanguage={currentLanguage}
                      setShowSmallprintModal={setShowSmallprintModal}
                      setSmallprintModalImageSrc={setSmallprintModalImageSrc}
                      setSmallprintModalText={setSmallprintModalText}
                    />
                  }
                />
                <Route
                  path="/apollo"
                  element={
                    <ActivateApolloPage
                      currentLanguage={currentLanguage}
                      setShowSmallprintModal={setShowSmallprintModal}
                      setSmallprintModalImageSrc={setSmallprintModalImageSrc}
                      setSmallprintModalText={setSmallprintModalText}
                    />
                  }
                />
                <Route
                  path="/confirm"
                  element={
                    <ConfirmPage
                      setLanguage={setLanguage}
                      currentLanguage={currentLanguage}
                    />
                  }
                ></Route>
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </div>
          </div>
        </div>
      </Animation>

      <div
        className="w-full flex justify-center items-center z-1 min-h-[70vh] p-8 my-12"
        id="setup"
      >
        <div className="w-11/12 max-w-[1300px] text-center">
          <h2
            className="bold text-4xl"
            dangerouslySetInnerHTML={{ __html: t('steps.title') }}
          />
          <div className="grid grid-cols-12 gap-x-4 my-24 place-items-center">
            <Step
              title={t('steps.one.title')}
              subtitle={t('steps.one.subtitle')}
              text={t('steps.one.text')}
              img="/img/iokey_step1.png"
            />
            <Step
              title={t('steps.two.title')}
              subtitle={t('steps.two.subtitle')}
              text={t('steps.two.text')}
              img="/img/iokey_step2.png"
            />
            <Step
              title={t('steps.three.title')}
              subtitle={t('steps.three.subtitle')}
              text={t('steps.three.text')}
              img="/img/iokey_step3.png"
            />
          </div>
          <div className="grid grid-cols-12 gap-x-4 my-24 place-items-center">
            <Step
              title={t('steps_minion.one.title')}
              subtitle={t('steps_minion.one.subtitle')}
              text={t('steps_minion.one.text')}
              img="/img/minion_step1.png"
            />
            <Step
              title={t('steps_minion.two.title')}
              subtitle={t('steps_minion.two.subtitle')}
              text={t('steps_minion.two.text')}
              img="/img/minion_step2.png"
            />
            <Step
              title={t('steps_minion.three.title')}
              subtitle={t('steps_minion.three.subtitle')}
              text={t('steps_minion.three.text')}
              img="/img/minion_step3.png"
            />
          </div>
        </div>
      </div>

      <div className="w-full flex justify-center items-center z-1 min-h-[60vh] p-8 mb-12">
        <div className="w-full flex flex-col justify-center text-center gap-y-16">
          <h2 className="bold text-4xl">{t('hero.title')}</h2>
          <Animation className="w-full flex flex-col justify-center">
            <img
              alt=""
              className="object-contain h-[300px]"
              src="/img/iokey.png"
            />
          </Animation>
        </div>
      </div>

      <div className="w-full flex text-left justify-start md:justify-center flex-col md:flex-row gap-x-12 gap-y-1 z-1 p-8">
        <button
          className="hover:text-primary text-left"
          onClick={() => setImprintOpen(true)}
        >
          {t('footer.site_notice.label')}
        </button>
        <a
          href={t('footer.privacy.customurl')}
          data-title={t('footer.privacy.title')}
          target="_blank"
          rel="noreferrer"
        >
          {t('footer.privacy.label')}
        </a>
        <a
          href={t('footer.terms_and_conditions.customurl')}
          data-title={t('footer.terms_and_conditions')}
          target="_blank"
          rel="noreferrer"
        >
          {t('footer.terms_and_conditions.label')}
        </a>
        <a
          href={t('footer.ats_eula.customurl')}
          data-title={t('footer.ats_eula')}
          target="_blank"
          rel="noreferrer"
        >
          {t('footer.ats_eula.label')}
        </a>
        <a
          href={t('footer.ats_foss_apollo.customurl')}
          data-title={t('footer.ats_foss_apollo')}
          target="_blank"
          rel="noreferrer"
        >
          {t('footer.ats_foss_apollo.label')}
        </a>
        <a
          href={t('footer.ats_foss_iokey.customurl')}
          data-title={t('footer.ats_foss_iokey')}
          target="_blank"
          rel="noreferrer"
        >
          {t('footer.ats_foss_iokey.label')}
        </a>
        <a
          href={t('footer.ats_foss_minion.customurl')}
          data-title={t('footer.ats_foss_minion')}
          target="_blank"
          rel="noreferrer"
        >
          {t('footer.ats_foss_minion.label')}
        </a>
      </div>

      <ImprintModal
        imprintIsOpen={imprintIsOpen}
        setImprintOpen={setImprintOpen}
        t={t}
      />

      <ErrorModal
        errorModalIsOpen={errorModalIsOpen}
        setErrorModalOpen={setErrorModalOpen}
        t={t}
        errorMessageBody={errorMessageBody}
        errorMessageHeading={errorMessageHeading}
      />

      <SuccessModalApollo
        t={t}
        showSuccessModalApollo={showSuccessModalApollo}
        setShowSuccessModalApollo={setShowSuccessModalApollo}
      />

      <SuccessModalCumulocity
        t={t}
        showSuccessModalCumulocity={showSuccessModalCumulocity}
        setShowSuccessModalCumulocity={setShowSuccessModalCumulocity}
        domain={url}
        article={article}
      />

      <SmallprintModal
        t={t}
        showSmallprintModal={showSmallprintModal}
        setShowSmallprintModal={setShowSmallprintModal}
        smallprintModalText={smallprintModalText}
        smallprintModalImageSrc={smallprintModalImageSrc}
      />
    </Router>
  );
}

export default App;
