import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmLogic from './confirmLogic';
import { useSearchParams } from 'react-router-dom';

// this file contains confirm page needed for creating / registrating apollo

interface IConfirmPage {
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  currentLanguage: string;
}

function ConfirmSignupPage({ setLanguage, currentLanguage }: IConfirmPage) {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [apolloId, setApolloId] = useState<string>('');
  const [alreadyConfirmed, setAlreadyConfirmed] = useState<boolean>(false);

  // set page title
  useEffect(() => {
    document.title = t('form.title_apollo');
  }, [currentLanguage, t]);

  useEffect(() => {
    const handlePageLoad = async () => {
      const language = searchParams.get('language');

      if (language) {
        setLanguage(language);
        i18n.changeLanguage(language);
      }

      const token = searchParams.get('token');
      if (token) {
        try {
          setLoading(true);
          let res = await fetch('/api/v1/assignment/confirm', {
            method: 'POST',
            headers: new Headers({
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json'
            })
          });
          setLoading(false);

          if (!res.ok) {
            if (res.status === 304) {
              setAlreadyConfirmed(true);
            } else {
              setAlreadyConfirmed(false);
            }
          } else {
            setSuccess(true);
            const data = await res.json();
            setApolloId(data.apolloId);
          }
        } catch (error) {
          setLoading(false);
          setSuccess(false);
        }
      }
    };
    if (apolloId === '') handlePageLoad();
  }, [i18n, searchParams, setLanguage, apolloId]);

  return (
    <div>
      <div className="w-full flex justify-center items-center flex-col p-6">
        <ConfirmLogic
          t={t}
          success={success}
          loading={loading}
          heading={t('apollo.confirm.heading_signup')}
          subheading={'apollo ID: ' + apolloId}
          errorHeading={t('apollo.errors.heading')}
          errorText={
            alreadyConfirmed
              ? t('errors.already_confirmed')
              : t('apollo.confirm.errorText')
          }
        />
        <a
          href="/"
          type="submit"
          className="btn p-4btn btn-lg btn-primary focus:bg-primary rounded-none text-white text-2xl uppercase"
        >
          <p className="text-2xl">{t('apollo.confirm.button')}</p>
        </a>
      </div>
    </div>
  );
}

export default ConfirmSignupPage;
